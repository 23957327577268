<template>
    <div class="content-wrapper">
        <PageHeader screenName="Cadastrar novo tópico" :linkItems="linkItems"/>

        <ConfirmModal
            itemIdentifier="usuário"
            :modalShow="modal.showModal"
            :typeModal="modal.typeModal"
            @confirmed="redirecionarLista"
            @canceled="closeModalConfirm"
        />

        <SucessoModal
            :modalShow="modalSucesso.showModal"
            :typeModal="modalSucesso.typeModal"
            :caseModal="modalSucesso.caseModal"
            @confirmed="redirecionarLista"
        />

        <TimedAlert variant="success" message="Tópico de ajuda salvo com sucesso!" :show="savedAlert" />
        <TimedAlert variant="error" :message="this.form.errorApi.error" :show="!!this.form.errorApi.error" />

        <main class="card p-2">
            <section class="form">
                <validation-observer ref="ajudaRules">
                    <b-form>
                        <b-row>
                            <b-col lg="6" md="6" sm="12">
                                <b-form-group label="Título do tópico *">
                                    <validation-provider
                                        #default="{ errors }"
                                        name="Topico"
                                        rules="required"
                                    >
                                        <b-form-input
                                            :state="errors.length > 0 || form.errorApi.topico? false:null"
                                            id="topico-input"
                                            v-model="form.topico"
                                            placeholder="Ex: Como fazer uma determinada ação?"
                                            :disabled="disabledAll"

                                        />
                                        <small v-if="errors[0]" class="text-danger">
                                            Este campo é de preenchimento obrigatório.
                                        </small>
                                        <small v-if="form.errorApi.topico" class="text-danger">
                                            {{ form.errorApi.topico }}
                                        </small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col lg="6" md="6" sm="12">
                                <validation-provider #default="{ errors }" name="Categorias" rules="required">
                                    <b-form-group label="Categoria *" :state="errors.length > 0 || form.errorApi.id_categoria ? false:null">
                                        <v-select
                                            id="Select-Categoria"
                                            :reduce="(option => option.id_categoria)"
                                            v-model="form.id_categoria"
                                            @input="SelecionaCategoria"
                                            variant="custom"
                                            item-text="titulo"
                                            item-value="id_categoria"
                                            label="titulo"
                                            placeholder="Selecione a categoria relacionada"
                                            :disabled="disabledAll"
                                            :options="Categorias"
                                        >
                                            <span slot="no-options">Nenhuma opção selecionável.</span>
                                        </v-select>
                                        <small v-if="errors[0]" class="text-danger">
                                            Este campo é de preenchimento obrigatório.
                                        </small>
                                        <small v-if="form.errorApi.id_categoria" class="text-danger">
                                            {{ form.errorApi.id_categoria }}
                                        </small>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <b-col lg="12" md="12" sm="12">
                                <validation-provider #default="{ errors }" name="Conteudo" rules="required">
                                    <b-form-group label="Descrição do tópico *" :state="errors.length > 0 || form.errorApi.conteudo ? false:null">
                                        <b-form-textarea
                                            id="textarea-default"
                                            placeholder="Insira o conteúdo do tópico"
                                            rows="3"
                                            v-model="form.conteudo"
                                            :disabled="disabledAll"
                                        />
                                        <small v-if="errors[0]" class="text-danger">
                                            Este campo é de preenchimento obrigatório.
                                        </small>
                                        <small v-if="form.errorApi.conteudo" class="text-danger">
                                            {{ form.errorApi.conteudo }}
                                        </small>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                        </b-row>
                    </b-form>
                </validation-observer>
            </section>
            <section class="buttons mt-3">
                <b-button id="save-faq"
                          class="mr-2"
                          variant="custom-blue"
                          @click.prevent="validationForm(true)"
                          :disabled="saving || savingAndNew || disabledAll"
                >
                    <feather-icon icon="CheckIcon" class="mr-50"/>
                    <span class="align-middle">Salvar tópico</span>
                    <span v-if="saving" class="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true"></span>
                </b-button>
                <b-button id="save-faq-new"
                          class="mr-2"
                          variant="custom-blue"
                          @click.prevent="validationForm(false)"
                          :disabled="saving || savingAndNew || disabledAll"
                >
                    <feather-icon icon="CheckIcon" class="mr-50"/>
                    <span class="align-middle">Salvar e cadastrar uma nova</span>
                    <span v-if="savingAndNew" class="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true"></span>
                </b-button>
                <b-button id="cancel-register-faq"
                          :disabled="saving || savingAndNew"
                          variant="outline-danger"
                          @click.prevent="openModalConfirm"
                >
                    <feather-icon icon="XIcon" class="mr-50"/>
                    <span class="align-middle">Cancelar</span>
                </b-button>
            </section>
        </main>
    </div>
</template>

<script>
import { BRow, BCol, BForm, BFormGroup, BFormInput, BFormRadio, BButton, BFormTextarea } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import TimedAlert from '@/views/components/custom/timed-alert/TimedAlert.vue'
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue'
import ConfirmModal from '@/views/components/custom/modals/ConfirmModal.vue'
import SucessoModal from '@/views/components/custom/modals/SucessoCriacaoEdicaoModal.vue'

export default {
    title: 'Cadastrar tópico de ajuda',
    components: {
        BRow, BCol, BForm, BFormGroup, BFormInput, BFormRadio, BFormTextarea,
        BButton, vSelect, ValidationProvider, ValidationObserver,
        TimedAlert, PageHeader, ConfirmModal, SucessoModal
    },

    data() {
        return {
            linkItems: [
                {
                    name: 'Ajuda e suporte',
                    routeName: 'ajuda-list'
                },
                {
                    name: 'Cadastrar novo tópico',
                    active: true
                }
            ],
            modal: {
                showModal: false,
                typeModal: 'danger'
            },
            modalSucesso: {
                showModal: false,
                typeModal: 'success',
                caseModal: 'criacao'
            },
            required,
            savedAlert: false,
            saving: false,
            savingAndNew: false,
            disabledAll: false,
            form: {
                topico: '',
                conteudo: '',
                url: '',
                id_categoria: '',
                errorApi: {
                    topico: '',
                    conteudo: '',
                    url: '',
                    id_categoria: '',
                    error: ''
                }
            },
            CategoriaSelecionada: [],
            Categorias: []
        }
    },

    methods: {

        SelecionaCategoria() {
            this.$emit("GetCategoria", this.CategoriaSelecionada);
        },

        buscaCategoria() {
            this.$http.get(this.$api.ajudaCategorias()).then((res) => {
                this.Categorias = res.data;
                this.disabledAll = false;
            });
        },

        validationForm(redirectToList) {
            this.$refs.ajudaRules.validate().then(success => {
                if (success) {
                    redirectToList ? this.saving = true : this.savingAndNew = true;
                    this.saveFaq(redirectToList);
                }
            })
        },

        saveFaq(redirectToList) {
            const parameters = {
                'topico': this.form.topico,
                'conteudo': this.form.conteudo,
                'id_categoria': this.form.id_categoria,
            }
            this.$http.post(this.$api.ajuda(), parameters).then(() => {
                if(redirectToList) {
                    this.openModalSucesso();
                }else{
                    this.savedAlert = !this.savedAlert;
                }

                this.saving = false;
                this.savingAndNew = false;
                this.cleanForm();

            }).catch(error => {
                this.saving = false;
                this.savingAndNew = false;
                if(typeof error.response != 'undefined'){
                    this.handleErrors(error.response)
                }else{
                    this.form.errorApi.error = 'Erro inesperado';
                }
            });
        },

        handleErrors(data) {
            if(data.errors) {
                if(typeof errors !== 'string') {
                    this.form.errorApi.topico = errors.topico ? errors.topico[0] : '';
                    this.form.errorApi.conteudo = errors.conteudo ? errors.conteudo[0] : '';
                    this.form.errorApi.url = errors.url ? errors.url[0] : '';
                    this.form.errorApi.categoriasSelecionadas = errors.id_categoria ? errors.id_categoria[0] : false;
                    this.form.errorApi.error = '';
                }else{
                    this.form.errorApi.topico = '';
                    this.form.errorApi.conteudo = '';
                    this.form.errorApi.url = '';
                    this.form.errorApi.categoriasSelecionadas = false;
                    this.form.errorApi.error = errors;
                }
            }
        },

        cleanForm() {
            this.form.topico = '';
            this.form.conteudo = '';
            this.form.url = '';
            this.categoriasSelecionadas = '';

            this.form.errorApi.topico = '';
            this.form.errorApi.conteudo = '';
            this.form.errorApi.url = '';
            this.form.errorApi.categoriasSelecionadas = false;
            this.form.errorApi.error = '';

            this.$refs.ajudaRules.reset();
        },

        redirecionarLista() {
            this.$router.push({ name: 'ajuda-list' });
        },

        openModalConfirm() {
            this.modal.showModal = true;
        },

        closeModalConfirm() {
            this.modal.showModal = false;
        },

        openModalSucesso() {
            this.modalSucesso.showModal = true;
        },
    },
    created() {
        this.buscaCategoria();
    },
}
</script>

'